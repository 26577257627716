<script>
    import StrGlowna from "./StrGlowna.vue"
    import OMnie from "./OMnie.vue"
    import MojeProjekty from "./MojeProjekty.vue"
    import NapisaliOMnie from "./NapisaliOMnie.vue"
    import MojKontakt from "./MojKontakt.vue"
    
    import PrjDomotykaKomputer from "./PrjDomotykaKomputer.vue"
    import PrjDomotyka from "./PrjDomotyka.vue"
    import PrjKabina3d from "./PrjKabina3d.vue"
    import PrjEkranDotykowy from "./PrjEkranDotykowy.vue"
    import PrjStolCNC from "./PrjStolCNC.vue"
    import PrjOzonator from "./PrjOzonator.vue"
    import PrjWzmacniacz from "./PrjWzmacniacz.vue"
    import PrjEkranLED from "./PrjEkranLED.vue"
    import PrjKomora from "./PrjKomora.vue"
    import PrjProgramCRM from "./PrjProgramCRM.vue"
    import PrjGlosowanie from "./PrjGlosowanie.vue"
    import PrjOdczucie from "./PrjOdczucie.vue"
    import PrjWentylatory from "./PrjWentylatory.vue"
    import PrjTester from "./PrjTester.vue"
    import PrjLokomotywa from "./PrjLokomotywa.vue"
    import PrjSuszarnia from "./PrjSuszarnia.vue"
    import PrjWiertarki from "./PrjWiertarki.vue"

    export default {
        name: "SrodekStrony",
        data() {
            return { 
                // strona: 'omnie'
            }
        },
        props: ['value'],
        computed:{
            PathName(){
                return window.location.pathname
            },
            LocalVModel: {
                get(){
                    // console.warn('get')
                    return this.value
                },
                set(wartosc) {
                    // console.warn('set')
                    this.$emit('input', wartosc)
                }
            }
        },
        components:{
            StrGlowna,
            OMnie,
            MojeProjekty,
            NapisaliOMnie,
            MojKontakt,
            //Projekty
            PrjDomotykaKomputer,
            PrjDomotyka,
            PrjKabina3d,
            PrjEkranDotykowy,
            PrjStolCNC,
            PrjOzonator,
            PrjWzmacniacz,
            PrjEkranLED,
            PrjKomora,
            PrjProgramCRM,
            PrjGlosowanie,
            PrjOdczucie,
            PrjWentylatory,
            PrjTester,
            PrjLokomotywa,
            PrjSuszarnia,
            PrjWiertarki,
        },
        methods:{
            ToIf(dana){
                return dana.router
            }
        }
    }
</script>

<template>
    <div>
        <StrGlowna v-if="PathName == '/'"></StrGlowna>
        <OMnie v-if="PathName == '/o-mnie'"></OMnie>
        <MojeProjekty v-if="PathName == '/moje-projekty'"></MojeProjekty>
        <NapisaliOMnie v-if="PathName == '/napisali-o-mnie'"></NapisaliOMnie>
        <MojKontakt v-if="PathName == '/kontakt'"></MojKontakt>
        <!-- PROJEKTY -->
        <PrjDomotykaKomputer v-if="PathName == '/domotyka-komputer'"></PrjDomotykaKomputer>
        <PrjDomotyka v-if="PathName == '/domotyka'"></PrjDomotyka>
        <PrjKabina3d v-if="PathName == '/kabina-multimedialna-3d'"></PrjKabina3d>
        <PrjEkranDotykowy v-if="PathName == '/wielkoformatowy-ekran-dotykowy'"></PrjEkranDotykowy>
        <PrjStolCNC v-if="PathName == '/frezarka-cyfrowa-cnc'"></PrjStolCNC>
        <PrjOzonator v-if="PathName == '/urzadzenie-medyczne-do-wprowadzania-ozonu-do-krwioobiegu-pacjenta'"></PrjOzonator>
        <PrjWzmacniacz v-if="PathName == '/wzmacniacz-estradowy-audio-duzej-mocy'"></PrjWzmacniacz>
        <PrjEkranLED v-if="PathName == '/wielkoformatory-ekran-graficzno-tekstowy-led'"></PrjEkranLED>
        <PrjKomora v-if="PathName == '/sterownik-komory-kriogenicznej'"></PrjKomora>
        <PrjProgramCRM v-if="PathName == '/dedykowany-system-crm-erp'"></PrjProgramCRM>
        <PrjGlosowanie v-if="PathName == '/system-przeznaczony-do-prowadzenia-glosowania'"></PrjGlosowanie>
        <PrjOdczucie v-if="PathName == '/system-prowadzacy-analize-reklam-multimedialnych'"></PrjOdczucie>
        <PrjWentylatory v-if="PathName == '/sterowniki-wentylatorow-lazienkowych'"></PrjWentylatory>
        <PrjTester v-if="PathName == '/tester-urzadzen-elektronicznych'"></PrjTester>
        <PrjLokomotywa v-if="PathName == '/uklad-sterowania-modelem-lokomotywy'"></PrjLokomotywa>
        <PrjSuszarnia v-if="PathName == '/sterownik-suszarni-lakieru-samochodowego'"></PrjSuszarnia>
        <PrjWiertarki v-if="PathName == '/oprogramowanie-dla-wiertarko-frezarki'"></PrjWiertarki>
    </div>
</template>