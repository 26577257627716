<script>
    import MyJumbotron from './komp/MyJumbotron.vue';
    import MyText from './komp/MyText.vue';
    import MyCarousel from './komp/MyCarousel.vue';
    import MyYoutube from './komp/MyYoutube.vue';

    export default{
        name: "PrjKabina3d",
        data() {
            return { 
                naglowek: {
                    "title":"kabina 3D",
                    "text": "kabina multimedialna 3D do symulacji i prezentacji pomieszczeń",
                    "rok": "projekt z roku 2012",
                    "dodatek": "urządzenie to zostało opatentowane"
                },
                opis1:` 
                    Urządzenie powstało w odpowiedzi na rosnące wymagania klientów dotyczące potrzeby sprawdzenia wyglądu i funkcjonalności 
                    przyszłych pomieszczeń w których będą mieszkali. Dotychczasowe rozwiązania symulacji komputerowej mają dwie zasadnicze wady,
                    z którymi spotykali się ludzie chcący zaprojektować i umeblować swoje mieszkanie. Pierwszym problemem było to, że każdy mógł
                    swoje wymarzone pomieszczenie oglądać tylko z perspektywy osoby znajdującej się na zewnątrz tego pomieszczenia, najczęściej
                    patrząc z daleka, bez praktycznej możliwości sprawdzenia wygody rozmieszczenia poszczególnych mebli i urządzeń. Drugą wadą
                    był problem obsługi oprogramowania, które służyło do projektowania pomieszczenia, chociaż oprogramowanie to z biegiem czasu
                    coraz bardziej zaczynało być przyjazne użytkownikowi.    
                `,
                opis2:`
                    Prezentowana Kabina Multimedialna eliminuje te dwa najważniejsze problemy, dodając szeroki wachlarz funkcjonalności,
                    niemożliwy do oddania w ręce Kowalskiego do tej pory. Każdy, kto będzie miał życzenie zaprojektować swoje wymarzone
                    pomieszczenia, dokona tego w ciągu kilku chwil. Podkreślić należy, że już w momencie projektowania może oglądać swoje
                    dokonania w rozmiarach rzeczywistych i podziwiać je z każdej strony wędrując po pomieszczeniu, dzięki zastosowaniu
                    specjalistycznej technologi trójwymiarowej. Nieocenioną zaletą urządzenia są dwie ściany interaktywne, umożliwiające
                    dowolne dodawanie, usuwanie i przemieszczanie umeblowania. Za pomocą dotknięcia jesteśmy w stanie przenosić przedmioty
                    warzące zbyt wiele dla człowieka bez najmniejszego wysiłku. W jednym miejscu na powierzchni 9 metrów kwadratowych możemy
                    mieć wybór będący wyposażeniem całego, nawet bardzo dużego sklepu i dowolnie go komponować. Oczywiście dzięki intuicyjnej
                    obsłudze, z urządzenia korzysta osoba zainteresowana zaprojektowaniem swojego domu, a nie wyspecjalizowany pracownik
                    techniczny. Całość projektowania sprowadza się do dotknięcia wybranego przez nas elementu, co ukaże gamę funkcji, które
                    możemy na nim wykonać. Naturalnie w równie prosty sposób możemy dodawać i usuwać dowolne elementy znajdujące się w "Pamięci"
                    urządzenia.
                `,
                opis3:`
                    Aby urządzenie było jeszcze wygodniejsze dla naszego Kowalskiego, zadbaliśmy o to, aby do jego obsługi nie trzeba było
                    stosować dodatkowych akcesorii, typu okulary trójwymiarowe, wskaźniki, kaski multimedialne itp. Należy tutaj podkreślić,
                    że każdy kto wejdzie do Kabiny od razu może stać się projektantem własnego domu, nie prosząc obsługi lub wykwalifikowanych
                    osób o dodatkowe często kosztowne akcesoria lub pomoc i od razu za pomocą dotyku zmieniać świat, wewnątrz którego się
                    znajduje. Kabina Multimedialna, którą mamy zaszczyt zaprezentować Państwu ma wymiary ścian o szerokości 3 metrów i wysokości
                    2,5 metra co daje możliwość rozglądania się wewnątrz pomieszczenia o powierzchni 9 metrów kwadratowych.
                `,
                opis4:`
                    Muszę przyznać, że pomimo moich starań nie jestem w stanie za pomocą filmów bądź zdjęć zaprezentować Państwu wrażenia jakie
                    odnosi osoba będąca wewnątrz Kabiny Multimedialnej. Uchwycenie tego uczucia za pomocą kamery niepotrafiącej obserwować
                    rzeczywistości w taki sposób jak człowiek, który się rozgląda i zwraca uwagę na poszczególne detale, jest praktycznie
                    niemożliwe.
                `,
                opis5:`
                    Dzięki temu, że w urządzeniu obraz nas otacza, możemy patrzeć w każdym interesującym nas kierunku i widzieć
                    dokładnie tak, jak byśmy stali wewnątrz pomieszczenia w danym miejscu. Oczywiście jeżeli zmienimy nasze miejsce obserwacji
                    całe nasze otoczenie zmieni się w sposób, abyśmy nadal widzieli rzeczywisty wygląd tylko z innego punktu widzenia.
                `,
                zdjecia1:[
                    {"obraz":"kabina2.jpg", "tytul": "proporcje wielkości", "opis":"wielkość urządzenia w porównaniu z człowiekiem"},
                    {"obraz":"kabina3.jpg", "tytul": "ściany dotykowe", "opis":"urządzenie posiada dwie takie ściany dotykowe"},
                    {"obraz":"kabina4.jpg", "tytul": "menu urządzenia", "opis":"wygoda obsługi dzięki przestrzennemu menu"},
                    {"obraz":"kabina1.jpg", "tytul": "pełne złudzenie 3D"}
                ],
                yuotube1:{
                    "zrodlo": "https://www.youtube.com/embed/SezsPfdZ-rk",
                    "opis": "Kabina do prezentacji i symulacji pomieszczeń"
                },
                yuotube2:{
                    "zrodlo": "https://www.youtube.com/embed/hDjg-NGPrNU",
                    "opis": "Widok z punktu widzenia osoby obserwującej"
                },
                yuotube3:{
                    "zrodlo": "https://www.youtube.com/embed/dpeqgvEIHVY",
                    "opis": "Zbiór kilku ujęć z punktu widzenia osoby obserującej"
                }
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel,
            MyYoutube
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
        <MyYoutube v-bind:myyoutube="yuotube1"></MyYoutube>
        <MyText v-bind:mytext="opis3"></MyText>
        <MyYoutube v-bind:myyoutube="yuotube2"></MyYoutube>
        <MyText v-bind:mytext="opis4"></MyText>
        <MyYoutube v-bind:myyoutube="yuotube3"></MyYoutube>
        <MyText v-bind:mytext="opis5"></MyText>
    </div>
</template>