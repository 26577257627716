<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default{
        name: "PrjTester",
        data() {
            return { 
                naglowek: {
                    "title":"tester elektroniczny",
                    "text": "testujący jednocześnie całe formatki produkcyjne",
                    "rok": "projekt z roku 2004",
                    // "dodatek": "urządzenie to zostało opatentowane"
                },
                opis1:` 
                    Na zlecenie firmy DOSPEL opracowany został tester urządzeń elektronicznych. Tester ten służy do testowania formatek
                    produkcyjnych zmontowanych przez firmę wentylatorów. Urządzenie to zostało zaprojektowane z myślą o dokładności i prędkości
                    testowania. Prędkość przetestowania całej formatki produkcyjnej wynosi nie dłużej niż 5s. 
                `,
                opis2:`
                    Dodatkowe funkcje tego urządzenia umożliwiają podgląd dokładnych parametrów każdej z testowanych płytek np.: Czas po którym
                    urządzenie wyłączy prąd zasilający silnik wentylatora. Pomiar ten przeprowadzany jest w czasie rzeczywistym, co umożliwia
                    bieżące śledzenie czasów wyłączeń każdego z urządzeń osobno nie czekając na rzeczywiste odłączenie silnika. Przejrzenie
                    poszczególnych czasów trwa nie dłużej niż 0,5s + czas potrzebny na przeczytanie danych.
                `,
                zdjecia1:[
                    {"obraz":"tester1.jpg"},
                    {"obraz":"tester2.jpg"},
                ],
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
    </div>
</template>