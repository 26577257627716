<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default{
        name: "PrjOzonator",
        data() {
            return { 
                naglowek: {
                    "title":"urządzenie medyczne",
                    "text": "do wprowadzania ozonu do krwioobiegu pacjenta",
                    "rok": "projekt z roku 2006",
                    "dodatek": "urządzenie posiada Certyfikat Medyczny CE"
                },
                opis1:` 
                    Projekt ten pod względem rozwiązania sterowania elektronicznego i oprogramowania został zaprojektowany w całosci przeze mnie.
                    Mechaniczne rozwiazanie urządzenia zostało zaprojektowane przez zespół innych ludzi. Generator Ozonu używany w urządzeniu
                    został opracowany przez inny zespół elektronikow.
                `,
                opis2:`
                    Projektowany przeze mnie układ elektroniczny ma za zadanie utrzymanie wartości zadanej ozonu, jak również bardzo precyzyjne
                    sterowanie ciśnieniem tlenu, który podłączony jest za pomocą przewodu, igły i wprowadzony bezpośrednio do żyły Pacjenta.
                `,
                opis3:`
                    Największym wyzwaniem w pracach nad projektowaniem urządzenia było utrzymanie jego stabilności pracy. Utrudnione to było
                    ze wzgledu na to, iż wszystkie układy sterujące urządzenia zostały umieszczone na jednej płytce drukowanej mieszczącej się
                    pod wyświetlaczem ciekłokrystalicznym urządzenia. Na dodatkową uwage zasługuje fakt, iż generator ozonu (nie projektowany
                    przeze mnie) wymagał sterowania przekaźnikami, których układy sterujące musiałem umieścic na mojej części urządzenia, co
                    spowodowało ogólny przepływ pradu przez moją część urządzenia do 10 Amperów. Pracując w takich warunkach urządzenie którego
                    część ja projektowałem musiało utrzymać bardzo wysoką stabilność pracy ze względu na to, iż wpływ jego decyzji miało
                    bezpośredni wpływ na zdrowie pacjenta podłączonego do tego urządzenia. Dodatkowo urządzenie musiało przejść przez testy
                    certyfikacji medycznej, która jest bardzo surowa dla rozwiazan elektronicznych, testując je pod wzgledem zakłóceń
                    i stabilności pracy.
                `,
                opis4:`
                    W urządzeniu tym została umieszczona również duża ilość dodatkowych modułów takich jak:
                `,
                opis5:`
                    Układ zapobiegający rozgrzewaniu się cewek przekaźnikow, którego zadaniem było obniżenia prądu płynącego przez cewki
                    przekaźnikow po ich właczeniu.
                `,
                opis6:`
                    Wzmacniacz multimedialny o mocy 3W służący do generowania przyjaznych dźwięków przez urzadzenie (innych niż tylko
                    piszczenie) takich jak dzwięk dzwonka lub polecenie głosowe.
                `,
                opis7:`
                    Układ impulsatora (podobnego do tych stosowanych na przykład w odtwarzaczach DVD do zmian lub przewijania filmu)
                    ułatwiajacego pracę.
                `,
                opis8:`
                    Układ pamięci o pojemności umożliwiającej wgranie kilku rozwiązań graficznych pojawiających się na wyświetlaczu,
                    lub umieszczenie kilku wersji językowej urządzenia zarówno graficznej jak i dzwiękowej.
                `,
                opis9:`
                    Podłączenie urządzenia do komputera PC w celu dokonywania wyżej wymienionych zmian bez ingerencji w oprogramowanie urządzenia.
                `,
                zdjecia1:[
                    {"obraz":"ozon1.jpg", "tytul": "całość urządzenia", "opis":"wraz z wyświetlaczem graficznym LCD 128x64"},
                    {"obraz":"ozon2.jpg", "tytul": "strona top sterownika"},
                    {"obraz":"ozon3.jpg", "tytul": "strona bottom sterownika"},
                ],
                yuotube1:{
                    "zrodlo": "https://www.youtube.com/embed/SezsPfdZ-rk",
                    "opis": "Kabina do prezentacji i symulacji pomieszczeń"
                },
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyText v-bind:mytext="opis2"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis3"></MyText>
        <MyText v-bind:mytext="opis4"></MyText>
        <MyText v-bind:mytext="opis5"></MyText>
        <MyText v-bind:mytext="opis6"></MyText>
        <MyText v-bind:mytext="opis7"></MyText>
        <MyText v-bind:mytext="opis8"></MyText>
        <MyText v-bind:mytext="opis9"></MyText>
    </div>
</template>