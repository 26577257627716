<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default{
        name: "PrjSuszarnia",
        data() {
            return { 
                naglowek: {
                    "title":"sterownik suszarni lakieru samochodowego",
                    "rok": "projekt z roku 2003",
                },
                opis1:` 
                    Urządzenie służy do kompleksowej obsługi mechanicznej części suszarni lakieru samochodowego. Część mechaniczną suszarni wykonał
                    inny podwykonawca. 
                `,
                opis2:`
                    Rozwiązanie to, steruje między innymi takimi najważniejszymi elementami suszarni jak wentylatory i nagrzewnice i kompleksowo
                    automatyzuje całość procesu. Utrzymuje stałe zadane parametry suszenia lakieru. Dodatkowa funkcjonalność utrzymuje również 
                    kontrolę nad pozostałą częścią mechaniczną suszarni. Przewidziana została funkcjonalność nadzorująca proces rozpoczęcia i 
                    kończenia procesu suszenia, jak również wszelkiego typu procesy zapobiegające przegrzewaniu się urządzenia, schładzania 
                    nagrzewnic itp.
                `,
                zdjecia1:[
                    {"obraz":"suszarnia.jpg"},
                ],
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
    </div>
</template>