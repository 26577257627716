<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'

    export default{
        name: "PrjWzmacniacz",
        data() {
            return { 
                naglowek: {
                    "title":"wzmacniacz estradowy audio",
                    "text": "o wysokiej mocy 2x1200 WAT Sinus",
                    "rok": "projekt z roku 1996",
                },
                opis1:` 
                    Chciałbym podkreślić, iż moc wzmacniacza podana jest w jednostce sinusoidalnej, co w mniemaniu popularnym dotyczących mocy
                    układów audio wyrażanej w mocy muzycznej jest praktycznie dwukrotnie większa.
                `,
                opis2:`
                    Jest to Wzmacniacz sygnału audio, który służy do zasilenia zestawów kolumnowych nagłośnienia estradowego. Wzmacniacz pracuje
                    w klasie AB. Jest to analogowe rozwiązanie urządzenia, którego jakość dźwięku jest w pełni zgodna ze standardem Hi-Fi.
                    Zastosowanie na przykład trzech takich wzmacniaczy w zupełności wystarcza do nagłośnienia powierzchni dziesięciu tysięcy 
                    metrów kwadratowych, oczywiście z odpowiednią ilością kolumn estradowych wytrzymujących tę moc.
                `,
                opis3:`
                    Największym problemem podczas opracowywania tego urządzenia było utrzymanie wysokiej jakości dźwięku przy bardzo dużych
                    napięciach i prądach urządzenia. Napięcie zasilające końcówkę mocy wzmacniacza przekraczało napięcie gniazdka sieciowego
                    230V. Dodatkowo pobór mocy, zważywszy że we wzmacniaczach tego typu sprawność, wynosi około 50%, musiał zostać rozwiązany.
                    Przy tej wydajności wzmacniacza należało zapewnić stabilny pod względem tętnień i zakłóceń zasilacz o mocy Pięciu Tysięcy Wat.
                    Moc potrzebna do sterowania tranzystorów Końcówki Mocy była tak duża, iż potrzebny był wzmacniacz o mocy 2x80 wat aby
                    dostarczyć energii sterującej do końcówki. Należy również podkreślić problemy związane z chłodzeniem urządzenia, ponieważ
                    pozostała część energii, która nie może zostać wykorzystana jako dźwięk, musi zostać rozproszona za pomocą radiatorów i
                    wymuszonego obiegu powietrza. Należało przy tym odpowiednio obliczyć powierzchnie chłodzące, jak również odpowiednio
                    zaprojektować niezbędne płytki drukowane, aby rozkład tranzystorów końcówki mocy umożliwił przekazanie energii cieplnej
                    do radiatorów. Przy projektowaniu płytek drukowanych należało również wziąć pod uwagę duże wartości prądów, które rozgrzewają
                    ścieżki obwodu. Przy tym zadaniu należało zastosować takie szerokości ścieżek drukowanych, aby przy danej grubości miedzi na
                    laminacie nie uległy zniszczeniu, i w drugą stronę aby nie były zbyt szerokie, żeby nie powiększać bezcelowo płytek
                    drukowanych. W trakcie projektu obok tych wszystkich problemów należało odpowiednio prowadzić ścieżki zasilania Zerowego
                    (masy), tak aby sygnał z wyjścia wzmacniacza nie przedostał się do jego wejścia, gdyż spowodowało by to samowzbudzenie i
                    uszkodzenie wzmacniacza jak również głośników wysoko tonowych w zestawach kolumnowych.
                `,
                opis4:`
                    Jestem również autorem rozwiązań wzmacniaczy o niższych mocach, jednak ten przykład obrazuje całość doświadczenia, które
                    zdobyłem projektując wzmacniacze o coraz wyższych mocach.
                `,
                zdjecia1:[
                    {"obraz":"kabina2.jpg", "tytul": "proporcje wielkości", "opis":"wielkość urządzenia w porównaniu z człowiekiem"},
                    {"obraz":"kabina3.jpg", "tytul": "ściany dotykowe", "opis":"urządzenie posiada dwie takie ściany dotykowe"},
                ],
                yuotube1:{
                    "zrodlo": "https://www.youtube.com/embed/SezsPfdZ-rk",
                    "opis": "Kabina do prezentacji i symulacji pomieszczeń"
                },
            }
        },
        components:{
            MyJumbotron,
            MyText
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyText v-bind:mytext="opis2"></MyText>
        <MyText v-bind:mytext="opis3"></MyText>
        <MyText v-bind:mytext="opis4"></MyText>
    </div>
</template>