<script>
    export default {
    name: "TopStrony",
    data() {
        return {};
    },
    props: ["value"],
    computed: {
        LocalVModel: {
            get() {
                return this.value;
            },
            set(wartosc) {
                this.$emit("input", wartosc);
            }
        }
    },
    methods: {
        DoPozycji(Dana) {
            this.LocalVModel.router = Dana
        },
        Domena() {
            return window.location.hostname
        }
    }
}
</script>

<template>
    <div>
        <!--  type="dark" variant="dark" -->
        <b-navbar toggleable="lg" >
            <b-navbar-brand  href="/" @click="DoPozycji(['Strona glowna'])">
                <div v-if="(Domena() == 'hicad.pl') | (Domena() == 'www.hicad.pl')" >
                    <img src="/images/logoHiCad.png" alt="..." height="40">
                </div>
                <div v-if="(Domena() == 'adambartoszewicz.pl') | (Domena() == 'www.adambartoszewicz.pl')" class="font-italic" >
                    <b>Adam Bartoszewicz</b>
                </div>
              </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" class="pl-3" is-nav > 
                <b-navbar-nav class="ml-auto">
                    <b-nav-item href="o-mnie">O mnie</b-nav-item>
                    <b-nav-item href="moje-projekty">Moje projekty</b-nav-item>
                    <b-nav-item href="napisali-o-mnie">Napisali o mnie</b-nav-item>
                    <b-nav-item href="kontakt">Kontakt</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <!-- {{ LocalVModel }} -->
    </div>
</template>
