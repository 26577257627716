<script>
/** 
 * Tutaj Lepszym rozwiązaniem było by zastosowanie komponentu bootstrap EMBED
 * https://bootstrap-vue.org/docs/components/embed
*/
    export default{
        name: "MyYoutube",
        data() {
            return { 
                
            }
        },
        /** 
         * PROPS MYCARD
         * {
         *    "zrodlo": "https://www.youtube.com/embed/SezsPfdZ-rk",
         *    "opis": "Kabina do prezentacji i symulacji pomieszczeń"
         * }
        */
        props: ["myyoutube"]
    }
</script>

<template>
    <div>
        <div class="row justify-content-md-center">
            <div class="col col-sm-12 col-md-10 col-lg-8 pb-4">
                <div v-if="myyoutube.zrodlo" class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" 
                        v-bind:src="myyoutube.zrodlo"
                        title="YouTube video player" 
                        frameborder="0" 
                        allowfullscreen>
                    </iframe>
                </div>
                <div v-if="myyoutube.opis" class="pt-2">
                    <p align="center"><span class="bolded">{{ myyoutube.opis }}</span></p>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .bolded {
        font-weight: bold;
    }
</style>