<script>
    export default {
        name: "MyCard",
        data() {
            return { 

            }
        },
        /** 
         * PROPS MYCARD
         * {
         * "image":"ProgMikroko.jpg",
         * "title":"Mikrokontrolery",
         * "subtitle": "Projekt z roku 2025 ",
         * "text": "Programowanie mikrokontrolerów jednoukładowych",
         * "mylink": { "text":"Dowiedz się więcej", "sciezka": "/moje-projekty"},
         * "mylinkext": { "text":"Dowiedz się więcej", "sciezka": "/moje-projekty"}
         * } 
        */
        props: ["mycard"]
    }
</script>

<template>
    <div>
        <div class="card border-0">
            <img v-if="mycard.image" class="card-img-top px-4 pt-4" v-bind:src="'./images/'+mycard.image" v-bind:alt="mycard.title">
            <div class="card-body">
                <h5 class="card-title" align="center"><b>{{ mycard.title }}</b></h5>
                <h6 v-if="mycard.subtitle" align="center">{{ mycard.subtitle }}</h6>
                <p class="card-text" align="center">{{ mycard.text }}</p>
                <!-- Link Wewnętrzny -->
                <div v-if="mycard.mylink" align="center">
                    <h5> 
                        <b-link v-bind:href="mycard.mylink.sciezka">{{ mycard.mylink.text }}</b-link>
                    </h5>
                </div>
                <!-- Link Zewnętrzny -->
                <div v-if="mycard.mylinkext" align="center">
                    <h5> 
                        <b-link v-bind:href="mycard.mylinkext.sciezka" target="_blank">{{ mycard.mylinkext.text }}</b-link>
                    </h5>
                </div>
                <!-- Download -->
                <div v-if="mycard.mylinkdownload" align="center">
                    <h5> 
                        <b-link v-bind:href="mycard.mylinkdownload.sciezka" download>{{ mycard.mylinkdownload.text }}</b-link>
                    </h5>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

</style>
