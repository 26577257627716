<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default{
        name: "PrjWentylatory",
        data() {
            return { 
                naglowek: {
                    "title":"sterowniki wentylatorów",
                    "text": "trzy sterowniki wyzwalane na podstawie różnych zjawisk",
                    "rok": "projekt z roku 2005",
                    // "dodatek": "urządzenie to zostało opatentowane"
                },
                opis1:` 
                    Na zlecenie firmy AWENTA opracowane zostały trzy moduły sterowników do wentylatorów łazienkowo-kuchennych. 
                    Moduły te sterują silnikiem wentylatora na podstawie zjawisk przychodzących z zewnątrz wentylatora.
                    Każdy z tych modułów został opracowany w taki sposób, aby nawet nieprawidłowe podłączenie przez użytkownika nie uszkodziło
                    układu. 
                `,
                opis2:`
                    Moduł ten steruje silnikiem wentylatora na podstawie włączenia i wyłączenia światła w łazience. Po włączeniu światła
                    wentylator pracuje jeszcze przez czas ustawiony przez użytkownika.
                `,
                opis3:`
                    Moduł ten steruje silnikiem wentylatora na podstawie poziomu wilgoci panującego w pomieszczeniu. Czułość na wilgoć można
                    regulować co pozwala utrzymać pomieszczenie w bezpiecznym poziomie zawilgocenia. Po ustąpienia zjawiska silnik kręci się
                    jeszcze przez czas ustawiony przez użytkownika urządzenia. Dodatkowo w układzie została zaprojektowana funkcja włączania i
                    wyłączania wentylatora przez oświetlenie pomieszczenia tak jak w przypadku urządzenia pierwszego.
                `,
                opis4:`
                    Moduł ten steruje silnikiem wentylatora na podstawie poziomu hałasu panującego w pomieszczeniu. Jest to nowatorskie
                    rozwiązanie układu, które nie było nigdy wcześniej stosowane. Rozwiązanie to pozwala zamontować wentylator w pomieszczeniu
                    w którym światło jest zapalone przez cały czas (stacje benzynowe, toalety publiczne itp.). Wentylator włączy się w momencie,
                    kiedy usłyszy choćby hałas stukających butów, bądź zamykanych drzwi. Czułość układu na dźwięk można regulować w szerokim
                    zakresie od zjawisk naprawdę cichych (potrafi reagować na lekkie stukanie paznokciem w blat stołu z odległości dwóch metrów)
                    do bardzo głośnych (głośne zamykanie drzwi). Rozwiązanie elektroniczne zostało opracowane tak, aby układ nie reagował na
                    poziom hałasu panujący stale w pomieszczeniu (pobliska ulica, hałas panujący w pubie itp.) automatycznie dostosowując się do 
                    tła hałasu. Tutaj również uwzględniłem problem samego poziomu dźwięku generowanego przez silnik urządzenia.
                `,
                opis5:`
                    
                `,
                zdjecia1:[
                    {"obraz":"wentylator1.jpg"},                    
                ],
                zdjecia2:[
                    {"obraz":"wentylator2.jpg"},                    
                ],
                zdjecia3:[
                    {"obraz":"wentylator3.jpg"},                    
                ],
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>

        <MyCarousel v-bind:mycarousel="zdjecia3"></MyCarousel>
        <MyText v-bind:mytext="opis4"></MyText>

        <MyCarousel v-bind:mycarousel="zdjecia2"></MyCarousel>
        <MyText v-bind:mytext="opis3"></MyText>

        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
    </div>
</template>