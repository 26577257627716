<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'
    import MyYoutube from './komp/MyYoutube.vue'

    export default{
        name: "PrjDomotyka",
        data() {
            return { 
                naglowek: {
                    "title":"sterowanie inteligentnym domem",
                    "text": "zestaw czterech rodzajów modułów wykonawczych",
                    "rok": "projekt z roku 2016",
                    // "dodatek": "urządzenie to zostało opatentowane"
                },
                opis1:` 
                    Urządzenia te służą do sterowania inteligentnym domem. Moduły te umieszcza się w niewielkich skrzynkach sterujących po
                    stronie wykonawczej budynku. Niewielkie wymiary pozwalają uniknąć centralnej szafy sterującej w budynku na rzecz systemu
                    rozproszonego sterowania. 
                `,
                opis2:`
                    Dotychczasowe rozwiązania najczęściej opierały się na scentralizowanym sterowaniu Inteligentnym Budynkiem, co powodowało
                    zużycie bardzo dużej ilości i długości przewodów miedzianych, zajęcie dużej powierzchni poprzez szafy sterujące, jak również
                    specjalistyczną wręcz budowę budynku. Za takimi rozwiązaniami szły Duże koszta, co umożliwiało budowę takiego domu tylko przez
                    wąskie grono ludzi. Na rynku istnieją również rozwiązania sterujące poprzez sieć radiową. Urządzenia takie są drogie ze względu
                    na swoją skomplikowaną konstrukcję. Użytkownik zatem musi ograniczać się poprzez to co do ilości urządzeń, którymi chce 
                    sterować. Rozwiązania komunikacji radiowej posiadają praktycznie niemożliwą do wyeliminowania wadę, polegającą na możliwości
                    zakłócania ich z zewnątrz. Nie należy również zapominać, że nawet w przypadku komunikacji radiowej przewody doprowadzające energię
                    elektryczną do poszczególnych urządzeń i tak są niezbędne.
                `,
                opis3:`
                    Projektując zestaw urządzeń pogodziłem stabilność pracy i komunikacji pomiędzy urządzeniami z ilością używanych przewodów
                    miedzianych. Ilość połączeń elektrycznych praktycznie nie odbiega od standardowej instalacji elektrycznej obsługującej
                    ustaloną ilość urządzeń a wręcz, w sporej ilości przypadków, potrafi je ograniczyć. Rozwiąznie polega na doprowadzaniu do
                    małej skrzynki rozdzielczej zasilania 230V przeznaczonego dla urządzeń i przewodu komunikacyjnego RS485. Ze względu na budowę
                    i organizację modułów sterujących, pojedyncza niewielka rozdzielnia potrafi obsłużyć kilka pomieszczeń prostych w budowie
                    lub jedno mocno rozbudowane pomieszczenie mieszkalne. Napiszę o tym dalej. Prosty interfejs komunikacyjny z urządzeniami
                    pozwala na zastosowanie obecnych na rynku urządzeń sterujących Automatyką Przemysłową na przykład HMI, jak również komputerów
                    PC, na których pracować może już na prawdę rozbudowane oprogramowanie. Kompletne małe rozdzielnie umieszcza się niedaleko
                    pomieszczenia sterowanego i od nich rozprowadza się zasilania do poszczególnych urządzeń sterowanych
                `,
                opis4:`
                    Ze względu na małe wymiary pojedynczych skrzynek sterujących łatwo jest je rozproszyć po budynku i umieścić niedaleko lub
                    wewnątrz sterowanych pomieszczeń. Bliskość takiej rozdzielni pozwala na doprowadzenie wielu połączeń i czujników z
                    pomieszczenia bez zwiększania rozmiarów całej instalacji elektrycznej. Sygnały Sterujące z pojedynczych małych rozdzielni
                    trafiają do wspólnego komputera sterującego co pozwala praktycznie bez ograniczeń konfigurować wszystkie zależności pomiędzy
                    zjawiskami występującymi w całym budynku i poza nim. Zależności pomiędzy czujnikami a sterowanymi urządzeniami są właśnie
                    kwintesencją i sensem stosowania inteligentnego budynku. Prostota i taniość wykonania takiej instalacji pozwala maksymalizować
                    ilość zależności, a co za tym idzie spowodować, że nasz Inteligentny Budynek ma bardzo rozbudowane możliwości sterowania sobą
                    i podejmowania decyzji.
                `,
                zdjecia1:[
                    {"obraz":"domotyka1.jpg", "tytul": "wygląd urządzenia"},
                    {"obraz":"domotyka7.jpg", "tytul": "podłączenie urządzenia"},
                    {"obraz":"domotyka4.jpg", "tytul": "moduł typu Wejścia", "opis":"wraz z przykładowym oprzyrządowaniem"},
                    {"obraz":"domotyka8.jpg"},
                    {"obraz":"domotyka9.jpg", "tytul": "Szybkie podłączanie przekaźników", "opis":"dedykowany system ułatwiający montaż"},
                ],
                zdjecia2:[
                    {"obraz":"domotyka2.jpg", "tytul": "widok od strony Modbus"},
                    {"obraz":"domotyka3.jpg", "tytul": "widok od strony zasilania"},
                    {"obraz":"domotyka5.jpg", "tytul": "sterownik przekaźników", "opis":"wraz z połową maksymalnych możliwości sterujących"},
                    {"obraz":"domotyka6.jpg"},
                ],
                yuotube1:{
                    "zrodlo": "https://www.youtube.com/embed/PAVn3MMVbiM",
                    "opis": "Film prezentujący budowę wewnętrzną modułów"
                },
                naglowek1: {
                    "title":"moduł hC-PK 01",
                    "text": "moduł sterujący przekaźnikami",
                },
                opis5:`
                    Moduł ten steruje przekaźnikami wykonawczymi. Ilość Przekaźników, którymi pozwala sterować urządzenie to Czternaście
                    Sztuk. Taka siła wykonawcza umożliwia doprowadzenie sterowanego zasilania 230V do 14 pojedynczych urządzeń bądź na
                    przykład jak w przypadku rolet okiennych góra dół wysterować siedem takich rolet. Naturalnie jedno urządzenie z
                    zestawem przekaźników nie musi sterować takim samym typem urządzeń stąd możemy mieszać ze sobą sterowanie różnorodnymi
                    urządzeniami w jednym pomieszczeniu.
                `,
                opis6:`
                    Urządzenie zaprojektowane zostało w taki sposób, aby montaż jego wraz z Przekaźnikami Wykonawczymi stanowił jak najmniejszy
                    problem. Stąd stworzone zostały specjalne listwy, które montuje się bezpośrednio na 7 Przekaźnikach jednocześnie na ich złączach,
                    a do urządzenia podłącza się całość za pomocą Taśmy Połączeniowej. Pojedyncze urządzenie obsługuje Dwa takie bloki.
                    Istnieje możliwość skracania listwy połączeniowej (specjalnie do tego przeznaczone miejsca cięcia na listwie), przez co nie
                    jesteśmy zmuszani montować wszystkich 14 sztuk.
                `,
                naglowek2: {
                    "title":"moduł hC-WE 01",
                    "text": "moduł sterujący wejściami",
                },
                opis7:`
                    Moduł ten służy do obsługi wejść typu włączony wyłączony. Ilość wejść, którymi pozwala sterować urządzenie to
                    Czternaście Sztuk. Pojedyncze z czternastu wejść służy do wprowadzania informacji do systemu. Informacja taka
                    może pochodzić z włączników naściennych, czujników otwarcia okien drzwi itp, informacji pochodzących z systemu
                    alarmowego itp. Budowa modułu wejściowego została specjalnie zaprojektowana, aby możliwe było zarówno podanie napięcia na
                    wejście, jak również skonfigurować go tak, by to on dawał zasilanie dla czujnika i kontrolował otwarcie i zamknięcie obwodu.
                    Taka funkcjonalność jest bardzo wygodna, ponieważ pozwala na przykład bezproblemowo obsługiwać chwilowo naciskane włączniki
                    naścienne. Dodatkową funkcją, którą posiada każde z wejść jest liczenie ilości przychodzących sygnałów i czasu ich trwania.
                    W Przypadku włączników naściennych umożliwia to kontrolę czasu naciśnięcia włącznika i uzyskania z pojedynczego klawisza kilku
                    funkcji w zależności od czasu jego naciśnięcia. Wkomponowanie tej funkcji do urządzenia odciąża program główny sterujący domem.
                `,
                opis8:`
                    Aby łatwo podłączyć się do modułu została do niego zaprojektowana listwa, do której podłącza się przewody instalacyjne.
                    Najłatwiejszym i najprostszym przewodem, który warto zastosować jest przewód typu UTP (popularna skrętka internetowa),
                    który można wykorzystać również do komunikacji z czujnikami temperatury i wigoci.
                `,
                naglowek3: {
                    "title":"moduł hC-PRD 01",
                    "text": "moduł odczytu prądu",
                },
                opis9:`
                    Moduł ten służy do odczytu prądu płynącego w przewodzie elektrycznym. Informacja o płynącym prądzie wprowadzana jest za
                    pomocą przekładnika prądowego umieszczonego na przewodzie elektrycznym. Przy zastosowaniu różnych przekładników możemy
                    monitorować prąd w szerokim zakresie.
                `,
                opis10:`
                    Informacja o prądzie jest bardzo korzystna z punktu widzenia użytkownika i systemu sterującego. Wewnątrz urządzenia sygnał
                    z przekładnika odczytywany jest za pomocą przetworników analogowo cyfrowych co daje bezpośredni wgląd w wartość płynącego
                    prądu w przewodzie. Możemy za pomocą tej informacji stwierdzać pobór prądu przez poszczególne obwody w czasie. Informacje
                    takie pozwalają mieć wiedzę na temat poboru chwilowego jak i w dłuższym terminie prądu. Dodatkowo pozwalają wprowadzać kontrolę
                    nad podłączonymi urządzeniami do obwodów typu uszkodzona żarówka, zacięta przez oblodzenie roleta okienna, odłączone przez
                    użytkownika urządzenie z gniazdka elektrycznego, przeciążona pompa podlewania. Wymieniać można więcej zastosowań
                `,
                naglowek4: {
                    "title":"moduł hC-WIR 01",
                    "text": "moduł odczytu czujników 1-Wire",
                },
                opis11:`
                    Moduł ten służy do komunikacji z różnego rodzaju czujnikami umieszczonymi na magistrali 1Wire. Do czujników takich należy 
                    zaliczyć czujniki temperatury i wilgotności powietrza. Są to bardzo popularne i tanie czujniki występujące na rynku. Magistrala
                    ta jest bardzo wygodna, ponieważ pozwala na umieszczenie wielu czujników na jednym przewodzie, a co za tym idzie umieścić wiele
                    czujników w jednym lub kilku pomieszczeniach.
                `,
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel,
            MyYoutube
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
        <MyYoutube v-bind:myyoutube="yuotube1"></MyYoutube>
        <MyText v-bind:mytext="opis3"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia2"></MyCarousel>
        <MyText v-bind:mytext="opis4"></MyText>

        <MyJumbotron v-bind:myjumbotron="naglowek1"></MyJumbotron>
        <MyText v-bind:mytext="opis5"></MyText>
        <MyText v-bind:mytext="opis6"></MyText>

        <MyJumbotron v-bind:myjumbotron="naglowek2"></MyJumbotron>
        <MyText v-bind:mytext="opis7"></MyText>
        <MyText v-bind:mytext="opis8"></MyText>

        <MyJumbotron v-bind:myjumbotron="naglowek3"></MyJumbotron>
        <MyText v-bind:mytext="opis9"></MyText>
        <MyText v-bind:mytext="opis10"></MyText>

        <MyJumbotron v-bind:myjumbotron="naglowek4"></MyJumbotron>
        <MyText v-bind:mytext="opis11"></MyText>
    </div>
</template>