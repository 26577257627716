<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue';

    export default{
        name: "PrjKomora",
        data() {
            return { 
                naglowek: {
                    "title":"sterownik komory kriogenicznej",
                    "rok": "projekt z roku 2006",
                    "dodatek": "urządzenie posiada Certyfikat Medyczny CE"
                },
                opis1:` 
                    Projekt ten pod względem rozwiązania sterowania elektronicznego został zaprojektowany w całości przeze mnie. Mechaniczne
                    rozwiązanie urządzenia zostało zaprojektowane przez zespól innych inżynierów.
                `,
                opis2:`
                    Urządzenie to zostało zaprojektowane w celu sterowania całości komory kriogenicznej służącej do leczenia Pacjentów
                    ekstremalnie niskimi temperaturami. Układ elektroniczny ma za zadanie kontrolowanie całości układów mechanicznych i
                    czujników używanych w urządzeniu
                `,
            }
        },
        components:{
           MyJumbotron,
           MyText
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyText v-bind:mytext="opis2"></MyText>
    </div>
</template>