<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'
    import MyYoutube from './komp/MyYoutube.vue'

    export default{
        name: "PrjEkranDotykowy",
        data() {
            return { 
                naglowek: {
                    "title":"wielkoformatowy ekran dotykowy",
                    "text": `
                        ciekawostka: w tym roku najlepszym telefonem z ekranem dotykowym został Samsung Galaxy S I9000, 
                        mój ekran posiada już multi-touch`,
                    "rok": "projekt z roku 2010",
                },
                opis1:` 
                    Urzadzenie, ktore tutaj przedstawiam zostało w całosci zaprojektowane przeze mnie (Sprzet i Oprogramowanie). 
                    Wynalazek prezentowany był na Targach Nowosci Technicznych "Digital Sinage" w Essen w Niemczech.  
                `,
                opis2:`
                    Urządzenie to jest ekranem dotykowym wielkoformatowym, którego wymiary zostały sprawdzone w praktyce i wyniosły
                    trzy na cztery metry. Uważam, że urządzenie to powinno działać przy ekranie większym w szczególności jeżeli chodzi
                    o szerokość urządzenia, które jest praktycznie nieograniczona, jednak ze względów praktycznych, nie uzasadnione było
                    sprawdzanie ekranu o większej wielości (chodzi tutaj o wysokość człowieka i wyciągniętej przez niego reki w celu dotknięcia
                    ekranu). Ekran może stanowić tablicę interaktywną lub ciekawe rozwiązanie reklamy.
                `,
                opis3:`
                    Rozwiązanie sprzętowe jak i oprogramowanie współpracuje z systemem operacyjnym Windows i Linux Ubuntu (oczywiście po
                    kompilacji na dany system). Ekran w Systemie Windows obsługuje również funkcje wielopunktowego dotykania (multidotyk)
                    ekranu co umożliwia obsługę urządzenia przez wiele osób jednocześnie, szczególnie jeżeli ekran ten zostanie znacznie
                    rozciągnięty w osi X. Ilość Punktów które jednocześnie potrafi obsłużyć oprogramowanie zostało ograniczone do 100, ze
                    względu na moc obliczeniową potrzebną do analizy pracy rozwiązania, co i tak pięciokrotnie przekracza możliwości systemu
                    Windows 7.
                `,
                opis4:`
                    Mogę zdradzić, iż rozwiązania, o które opiera się urządzenie sięgają sieci neuronowych (sztucznej inteligencji).
                    Zastosowane tu oprogramowanie jest samo uczące się, co pozwala dowolnie zmieniać wielkości a nawet kształty ekranów.
                    W prezentowanym urządzeniu wykorzystane zostało rozwiązanie oparte o Tylną Projekcję Obrazu z Projektora Multimedialnego.
                `,
                opis5:`
                    Zdjęcia, które mam przyjemność Państwu zaprezentować pokazują ekran dotykowy troszkę mniejszy, jednak podobne rozwiązanie
                    zostało wykorzystane przeze mnie w późniejszym projekcie kabiny multimedialnej 3D. Zapraszam do zapoznania się z materiałami.
                `,
                zdjecia1:[
                    {"obraz":"ekran1.jpg", "tytul": "wielkość ekranu", "opis":"wielkość urządzenia w porównaniu ze mną"},
                    {"obraz":"ekran2.jpg", "tytul": "okienka windows", "opis":"poruszane dotykiem"},
                    {"obraz":"ekran3.jpg", "tytul": "prezentacja multi-touch", "opis":"dwie osoby korzystające jednocześnie"},
                    
                ],
                yuotube1:{
                    "zrodlo": "https://www.youtube.com/embed/q0o18CowfpA",
                    "opis": "Prezentacja na żywo działania ekranu i multi-touch"
                },
                
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel,
            MyYoutube
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
        <MyYoutube v-bind:myyoutube="yuotube1"></MyYoutube>
        <MyText v-bind:mytext="opis3"></MyText>
        <MyText v-bind:mytext="opis4"></MyText>
        <MyText v-bind:mytext="opis5"></MyText>
    </div>
</template>