<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default{
        name: "PrjProgramCRM",
        data() {
            return { 
                naglowek: {
                    "title":"dedykowany system CRM, ERP",
                    "text": "obsługa klienta, zasobów, produkcji przedsiębiorstwa",
                    "rok": "projekt z roku 2005",
                },
                opis1:` 
                    Program służy do zarządzania i organizacji przepływu dokumentów w firmie. Zakres jego możliwości zaczyna się od obsługi
                    Klienta i jego zamówienia, skończywszy na zautomatyzowanym przpeływie informacji po produkcji i obsługi magazynów. Przy
                    pomocy programu zbędne staje się prowadzenie dokumentacji w formie papierowej, zastępując ją w pełni elektroniczną.  
                `,
                opis2:`
                    Powoduje to, że zarówno Zarządzający firmą jak i pracownicy mają dostęp do potrzebnych im informacji za pomocą komputera.
                    Oprogramowanie to pozwala sporządzać wszelkiego rodzaju raporty, co umożliwia monitorowanie zarówno czasowe jak i
                    długoterminowe możliwości wykonawczych firmy i podejmować odpowiednie kroki, w celu usprawnienia mechanizmów działania
                    przedsiębiorstwa. Powoduje to maksymalizację zysków, minimalizację strat i sprawnego zarządzania zarówno działalnością firmy
                    jak i jej pracownikami.
                `,
                opis3:`
                    Powyżej zamieściłem tylko kilka zdjęć, ponieważ oprogramowanie to jest bardzo rozbudowane.
                `,
                zdjecia1:[
                    {"obraz":"oprogramowanie1.jpg"},
                    {"obraz":"oprogramowanie2.jpg"},
                    {"obraz":"oprogramowanie3.jpg"},
                    {"obraz":"oprogramowanie4.jpg"},
                ],
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
        <MyText v-bind:mytext="opis3"></MyText>
    </div>
</template>