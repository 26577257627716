<script>
/** 
 * Do Zmiany następnej strony można zastosować komponent PAGINATION
 * https://bootstrap-vue.org/docs/components/pagination
 * 
 * 
*/
    import MyJumbotron from "./komp/MyJumbotron.vue"
    import MyText from "./komp/MyText.vue"
    import MyCard from "./komp/MyCard.vue"

    const TextLink = "zobacz więcej"

    export default{
        name: "MojeProjekty",
        data() {
            return { 
                klasa: "col-md-6 col-lg-4 mb-3",
                naglowek: {
                    "title":"moje projekty",
                    "text": "",
                    "rok": "",
                    "dodatek": ""
                },
                wstep:`
                W dziale tym umieściłem tylko część opracowanych przeze mnie projektów. Niestety niektórymi z nich (najnowocześniejszymi
                na świecie) nie mam możliwości się pochwalić, ze względów natury prawnej projektu.
                `,
                domotykakomputer: {"image":"domotykakomputer1.jpg",
                    "title":"Komputer sterujący inteligentnym budynkiem",
                    "subtitle": "Projekt z roku 2017",
                    "text": `Oprogramowanie służy do zarządzania inteligentnym budynkiem. Dzięki lokalnej pracy
                             zapewnia stabilność pracy obiektu. Powiązania pomiędzy procesami sterującymi użytkownicy definiują samodzielnie, dzięki
                             zastosowaniu dedykowanego rozwiązania. Umożliwia dostęp zarówno lokalnie jak i w chmurze.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "domotyka-komputer"
                    }
                },
                domotyka: {"image":"domotyka1.jpg",
                    "title":"Zestaw modułów sterujących inteligentnym domem",
                    "subtitle": "Projekt z roku 2016",
                    "text": `Urządzenia te służą do sterowania inteligentnym domem. Moduły te umieszcza się w niewielkich skrzynkach sterujących
                             po stronie wykonawczej budynku. Niewielkie wymiary pozwalają uniknąć centralnej szafy sterującej w budynku na rzecz
                             systemu rozproszonego sterowania.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "domotyka"
                    }
                },
                kabina3d: {
                    "image":"kabina1.jpg",
                    "title":"Kabina multimedialna 3D do symulacji wyglądu pomieszczeń",
                    "subtitle": "Projekt z roku 2012",
                    "text": `Urządzenie służy do projektowania i symulacji wyglądu pomieszczeń w rzeczywistych wymiarach. W Kabinie
                             została zastosowania specjalistyczna technologia trójwymiarowa, polegająca na dostosowaniu się obrazu do
                             położenia oczu obserwatora.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "kabina-multimedialna-3d"
                    }
                },
                ekranDotykowy: {
                    "image":"ekran1.jpg",
                    "title":"Wielkoformatowy ekran dotykowy",
                    "subtitle": "Projekt z roku 2010",
                    "text": `Urządzenie to jest ekranem dotykowym wielkoformatowym. Rozwiązanie sprzętowe jak i oprogramowanie współpracuje
                             z Systemem operacyjnym Windows i Linux Ubuntu. Ekran w Systemie Windows obsługuje również funkcje wielopunktowego
                             dotykania ekranu co umożliwia obsługę urządzenia przez wiele osób jednocześnie.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "wielkoformatowy-ekran-dotykowy"
                    }
                },
                stolCNC: {
                    "image":"frezarka1.jpg",
                    "title":"Frezarka cyfrowa CNC",
                    "subtitle": "Projekt z roku 2009",
                    "text": `Jest to stół CNC o wielkości dwa na trzy i pół metra, sterowany komputerowo, nad którym umieszczone zostały
                             odpowiednie suwnice, na których umieszczone zostało wrzeciono frezarki. Urządzenie to umożliwia precyzyjne 
                             sterowanie wrzecionem frezarki z dokładnością do 0,1mm`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "frezarka-cyfrowa-cnc"
                    }
                },
                ozonator: {
                    "image":"ozon1.jpg",
                    "title":"Urządzenie medyczne do wprowadzania ozonu do krwioobiegu pacjenta",
                    "subtitle": "Projekt z roku 2006",
                    "text": `Projektowany przeze mnie układ elektroniczny ma za zadanie utrzymanie wartości zadanej ozonu jak również bardzo
                             precyzyjne sterowanie ciśnieniem tlenu, który podłączony jest za pomocą odpowiedniego przewodu i za pomocą igły
                             wprowadzony bezpośrednio do żyły Pacjenta.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "urzadzenie-medyczne-do-wprowadzania-ozonu-do-krwioobiegu-pacjenta"
                    }        
                },
                wzmacniacz: {
                    "image":"wzmacniacz.jpg",
                    "title":"Wzmacniacz estradowy audio o dużej mocy 2x1200 WAT SINUS",
                    "subtitle": "Projekt z roku 1996",
                    "text": `Jest to Wzmacniacz sygnału audio, który służy do zasilenia zestawów kolumnowych nagłośnienia estradowego.
                             Wzmacniacz pracuje w klasie AB. Jest to analogowe rozwiązanie urządzenia, którego jakość dźwięku jest w pełni 
                             zgodna ze standardem Hi-Fi.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "wzmacniacz-estradowy-audio-duzej-mocy"
                    }            
                },
                ekranLED: {
                    "image":"pixel2.jpg",
                    "title":"Wielkoformatowy ekran graficzno tekstowy LED",
                    "subtitle": "Projekt z roku 2005",
                    "text": `Ekran służy do wyświetlania reklam graficznych lub tekstowych. Został zbudowany z jednokolorowych diod LED
                             o dużej jasności i średnicy 10mm co pozwala na stworzenie bardzo dużych powierzchni. Umożliwia wyświetlanie
                             obrazów ruchomych o ilości 15 klatek na sekundę.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "wielkoformatory-ekran-graficzno-tekstowy-led"
                    }            
                },
                komoraKriogeniczna: {
                    // "image":"logo.jpg",
                    "title":"Sterownik komory kriogenicznej",
                    "subtitle": "Projekt z roku 2006",
                    "text": `Urządzenie to zostało zaprojektowane w celu sterowania całości komory kriogenicznej służącej do leczenia
                             Pacjentów ekstremalnie niskimi temperaturami. Układ elektroniczny ma za zadanie kontrolowanie całości układów
                             mechanicznych i czujników używanych w urządzeniu`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "sterownik-komory-kriogenicznej"
                    }               
                },
                programCRM: {
                    "image":"oprogramowanie1.jpg",
                    "title":"Dedykowany System CRM+ERP - Planowanie zasobów przedsiębiorstwa",
                    "subtitle": "Projekt z roku 2005",
                    "text": `Program służy do zarządzania i organizacji przepływu dokumentów w firmie. Zakres jego możliwości zaczyna się od
                             obsługi Klienta i jego zamówienia, skończywszy na zautomatyzowanym przpeływie informacji po produkcji i obsługi
                             magazynów.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "dedykowany-system-crm-erp"
                    }              
                },
                glosowanie: {
                    "image":"glosowanie1.jpg",
                    "title":"System przeznaczony do prowadzenia głosowania",
                    "subtitle": "Projekt z roku 2004",
                    "text": `Stworzone do prowadzenia walnych zgromadzeń i quizów. Posiada możliwość przesyłania danych z urządzenia na komputer
                             drogą radiową bądź przewodową, dzięki czemu jest uniwersalne w obsłudze. Urządzenie wyposażone jest w wyświetlacz, na
                             którym pokazywane są wszelkie niezbędne informacje, takie jak: numer urządzenia, aktualny czas, stan baterii,
                             informacja o sposobie komunikacji z komputerem (radio lub przewód), wybrana opcja głosowania.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "system-przeznaczony-do-prowadzenia-glosowania"
                    }             
                },
                odczucie: {
                    "image":"odczucie.jpg",
                    "title":"System prowadzący analizę reklam multimedialnych",
                    "subtitle": "Projekt z roku 2005",
                    "text": `Urządzenie przeznaczone jest do prowadzenia statystycznego odczucia odbioru reklamy telewizyjnej przez odbiorcę.
                             Jednym z największych wyzwań była ilość informacji, jaka musi zostać przekazana do komputera z urządzeń, ponieważ analiza
                             prowadzona jest w czasie rzeczywistym.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "system-prowadzacy-analize-reklam-multimedialnych"
                    }              
                },
                wentylatory: {
                    "image":"wentylator2.jpg",
                    "title":"Sterowniki wentylatorów",
                    "subtitle": "Projekt z roku 2005",
                    "text": `Na zlecenie firmy AWENTA opracowane zostały trzy moduły sterowników do wentylatorów łazienkowo-kuchennych.
                             Moduły te sterują silnikiem wentylatora na podstawie zjawisk przychodzących z zewnątrz wentylatora.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "sterowniki-wentylatorow-lazienkowych"
                    }             
                },
                tester: {
                    "image":"tester2.jpg",
                    "title":"Tester urządzeń elektronicznych",
                    "subtitle": "Projekt z roku 2004",
                    "text": `Na zlecenie firmy DOSPEL opracowany został tester urządzeń elektronicznych. Tester ten służy do
                             testowania formatek produkcyjnych zmontowanych przez firmę wentylatorów.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "tester-urzadzen-elektronicznych"
                    }            
                },
                lokomotywa: {
                    "image":"lokomotywa.jpg",
                    "title":"Układ sterowania modelem lokomotywy",
                    "subtitle": "Projekt z roku 2003",
                    "text": `Układ jest sterownikiem modelu lokomotywy. Wewnątrz procesora zaimplementowany został kompletny protokol DCC.
                             Umożliwia to umieszczenie więcej niż jednej lokomotywy na makiecie i sterowanie nimi za pomocą specjalnych 
                             urządzeń sterujących i programów komputerowych.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "uklad-sterowania-modelem-lokomotywy"
                    }               
                },
                suszarnia: {
                    "image":"suszarnia.jpg",
                    "title":"Sterownik suszarni lakieru samochodowego",
                    "subtitle": "Projekt z roku 2003",
                    "text": `Urządzenie służy do kompleksowej obsługi mechanicznej części suszarni lakieru samochodowego. Rozwiązanie to, 
                             steruje między innymi takimi najważniejszymi elementami suszarni jak wentylatory i nagrzewnice i kompleksowo 
                             automatyzuje całość procesu.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "sterownik-suszarni-lakieru-samochodowego"
                    }            
                },
                wiertarki: {
                    "image":"wiertarkofrezarki.jpg",
                    "title":"Oprogramowanie dla wiertarko-frezarek firmy WESSEL",
                    "subtitle": "Projekt z roku 2002",
                    "text": `Oprogramowanie to jest dedykowane specjalnie dla wiertarko-frezarek firmy WESSEL. Program, przekształca zbiory
                             z formatu typu GERBER na gotowy program wiertniczo-frezujący dla urządzenia.`,
                    "mylink":{
                        "text": TextLink,
                        "sciezka": "oprogramowanie-dla-wiertarko-frezarki"
                    }             
                },
            }
        },
        props: ["mycarousel"],
        components:{
            MyJumbotron,
            MyText,
            MyCard
        },
        computed:{
            /** Tutaj jest zalążek wytworzenia podstron, ponieważ jest tego dużo
             * Tutaj użyć komponentu PAGINATION
            */
            Tablica: function(){
                return [
                    this.domotyka,
                    this.wiertarki
                ]
            }
        },
        methods:{
            
        },
        created: function () {
            // console.warn(this.Tablica)
        }

    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="wstep"></MyText>
        <div class="row">
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="domotykakomputer"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="domotyka"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="kabina3d"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="ekranDotykowy"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="stolCNC"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="ozonator"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="wzmacniacz"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="ekranLED"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="komoraKriogeniczna"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="programCRM"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="glosowanie"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="odczucie"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="wentylatory"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="tester"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="lokomotywa"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="suszarnia"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="wiertarki"></MyCard>
            </div>
        </div>
    </div>
</template>