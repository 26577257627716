<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default{
        name: "PrjEkranLED",
        data() {
            return { 
                naglowek: {
                    "title":"ekran",
                    "text": "graficzno tekstowy LED",
                    "rok": "projekt z roku 2005",
                },
                opis1:` 
                    Ekran służy do wyświetlania reklam graficznych lub tekstowych. Został zbudowany z jednokolorowych diod LED o dużej jasności
                    i średnicy 10mm, co pozwala na stworzenie bardzo dużych powierzchni. Umożliwia wyświetlanie obrazów ruchomych o ilości 15 klatek
                    na sekundę. Układy elektroniczne zostały pomyślane w taki sposób, że niezależnie od wielkości ekranu, który będzie budowany,
                    grubość jego nie przekroczy najmniejszej grubości (ściany kostki) standardowego zasilacza komputerowego (o czym dalej) i pozostawi
                    dodatkowe miejsce na odprowadzanie ciepła wydzielanego przez zasilacze.
                `,
                opis2:`
                    Ekran został zaprojektowany jako zestaw dwóch modułów. Moduły te dzielą się na moduł sterujący i moduł na których umieszczone
                    zostały diody LED. Do jednego modułu sterującego można podłączyć osiem modułów zawierających diody. Moduł sterujący został
                    zaprojektowany w taki sposób, aby można było wykorzystać moduł zasilaczy komputerowych do zasilania całego ekranu. Jeden
                    zasilacz (w zależności od swojej mocy) pozwala zasilić kilka modułów sterownika (wraz z podłączonymi do niego modułami diod
                    LED). Dane dla modułu sterującego potrzebne do wyświetlania obrazu są przesyłane poprzez łącze RS-485 (niestandardowa
                    prędkość i protokół autorski) co pozwala oddalić komputer sterujący nawet o trzysta metrół. Dodatkowo łącze to zapewnia
                    bardzo dużą odporność na zakłócenia, co pozwoliło ograniczyć transmisję tylko w jedną stronę (bez potwierdzenia odbioru
                    danych). W przypadku wystąpienia zakłócenia, było ono niezauważalne ze względu na dużą ilość klatek przesyłanych do ekranu.
                    Zamiast sterowania komputerowego możliwe jest podłączenie prostego urządzenia na którym na karcie SD umieszczone są
                    poszczególne klatki obrazu. W takim przypadku przesyłany jest cały czas strumieñ danych, co zwalnia z korzystania z dużej
                    mocy obliczeniowej jaką dysponuje komputer.
                `,
                zdjecia1:[
                    {"obraz":"pixel1.jpg", "tytul": "sterownik wyświetlacza", "opis":"pojedynczego rzędu modułów LED"},
                    {"obraz":"pixel2.jpg", "tytul": "pojedynczy moduł", "opis":"można je łączyć praktycznie w dosolny sposób"},
                    {"obraz":"pixel3.jpg", "tytul": "przykładowa wyświetlana grafika", "opis":"w zależności od wielkości zbudowanego ekranu"},
                ],
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
    </div>
</template>