<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default{
        name: "PrjLokomotywa",
        data() {
            return { 
                naglowek: {
                    "title":"sterowanie modelem lokomotywy",
                    "rok": "projekt z roku 2003",
                },
                opis1:` 
                    Układ jest sterownikiem modelu lokomotywy. Wewnątrz procesora zaimplementowany
                    został kompletny protokół DCC. Umożliwia to umieszczenie więcej niż jednej
                    lokomotywy na makiecie i sterowanie nimi za pomocą specjalnych urządzeń
                    sterujących i programów komputerowych. 
                `,
                opis2:`
                    Na płytce zostało umieszczonych pięć dodatkowych wyjść sterujących.
                    Można je wykorzystywać na przykład do zapalenia światła lokomotywy. Jedno
                    z wyjść jest wysokoprądowe co pozwala na wysterowanie nim na przykład
                    grzałki do efektu dymu z komina lokomotywy. Zgodnie z normami protokołu
                    i takich rozwiązań układ zaslilany i sterowany jest bezpośrednio z torów
                    makiety. Przy zmianie software w procesorze układ nadaje się do sterowania
                    innymi modelami na makiecie takimi jak szlabany lub zwrotnice.
                `,
                zdjecia1:[
                    {"obraz":"lokomotywa.jpg"},
                ],
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
    </div>
</template>