<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default{
        name: "PrjGlosowanie",
        data() {
            return { 
                naglowek: {
                    "title":"system przeznaczony do prowadzenia głosowania",
                    "rok": "projekt z roku 2004",
                },
                opis1:` 
                    Stworzone do prowadzenia walnych zgromadzeń i quizów. Posiada możliwość przesyłania danych z urządzenia na komputer drogą
                    radiową bądź kablową, dzięki czemu jest uniwersalne w obsłudze. Urządzenie wyposażone jest w wyświetlacz, na którym pokazywane
                    są wszelkie niezbędne informacje, takie jak: numer urządzenia, aktualny czas, stan baterii, informacja o sposobie komunikacji
                    z komputerem (radio lub przewód), wybrana opcja głosowania.  
                `,
                opis2:`
                    Dołączona została rownież opcja wysyłania tekstów i reklam z komputera na wyświetlacz urządzenia. W urządzeniu zaprojektowana
                    została specjalna ładowarka akumulatorów dzięki której można ładować do 80 urządzeń na raz z jednego zasilacza.
                    Dla prawidłowego przebiegu głosowania urządzenie wyposażone zostało w czytnik kart chipowych. Dzięki tym udogodnieniom
                    urządzenie jest przyjazne dla użytkownika i proste w obsłudze. Dzięki rozbudowanym funkcjom urządzenia administrator
                    może: obsługiwać je z jednego miejsca, obserwować przebieg głosowania, czuwać nad stanem urządzeń, zdalnie nimi sterować np.
                    opcja wyłączania, blokowania urządzenia, monitoring stanu każdego urządzenia (np. Kontrola zasięgu radia, stanu akumulatora itp.).
                `,
                opis3:`
                    Do profesjonalnej obsługi walnych zgromadzeń i quizów została napisana aplikacja na PC, której konstrukcja pozwala na
                    jednoczesną obsługę dwóch monitorów. Na jednym z nich wyświetlane są informacje dla prowadzącego quiz bądź walne zgromadzenie
                    (np. o liczbie głosujących, ilości zebranych głosów, stanie urządzeń itd.). Natomiast informacje dla biorących w zgromadzeniu
                    lub w quizie osób (np. o czasie jaki pozostał do końca głosowania, treść pytania) wyświetlane są, oprócz na samym urządzeniu, również
                    na drugim monitorze lub obrazie z rzutnika graficznego.
                `,
                zdjecia1:[
                    {"obraz":"glosowanie1.jpg", "tytul": "wyglad urządzenia"},
                    {"obraz":"glosowanie2.jpg", "tytul": "główna płyta sterująca"},
                    {"obraz":"glosowanie3.jpg", "tytul": "wewnętrzna budowa urządzenia"},
                ],
                zdjecia2:[
                    {"obraz":"glosowanie4.jpg", "tytul": "oprogramowanie obsługujące"},
                ],
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia2"></MyCarousel>
        <MyText v-bind:mytext="opis3"></MyText>
    </div>
</template>