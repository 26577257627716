<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default{
        name: "PrjStolCNC",
        data() {
            return { 
                naglowek: {
                    "title":"frezarka cyfrowa CNC",
                    // "text": "",
                    "rok": "projekt z roku 2009",
                    // "dodatek": "urządzenie to zostało opatentowane"
                },
                opis1:` 
                    Urządzenie to zostało w całości zaprojektowane, zmontowane i zaprogramowane przeze mnie. Jest to stół CNC o wielkości
                    dwa na trzy i pół metra, sterowany komputerowo, nad którym umieszczone zostały odpowiednie suwnice na których umieszczone
                    zostało wrzeciono frezarki. Urządzenie to umożliwia precyzyjne sterowanie wrzecionem frezarki z dokładnością do 0,1mm.
                `,
                opis2:`
                    Do tego projekt ten umożliwia uzyskanie nacisku na frez umieszczony we wrzecionie z siłą 100kg w każdym kierunku X, Y, Z.
                    Maksymalna wielkość materiału, jaką można umieścić na stole to 2m na 3,5m na 40cm grubości.
                    Maksymalne obciążenie materiałem, jakie wytrzymuje blat stołu to jedna i pół tony. Przy umieszczeniu takiego ciężaru
                    na blacie roboczym maszyny, blat ugina się o 1mm. Rozwiązanie takie umożliwia prowadzenie frezowania w takich materiałach
                    jak kamień, glazura, stopy metali lekkich (mosiądz, aluminium), drewno.
                `,
                opis3:`
                    Zakres prac, jakie wykonałem, aby ono powstało dotyczy:
                `,
                opis4:`
                    Projektu całości rozwiązania mechanicznego włącznie z rysunkami technicznymi służącymi dla firm podwykonawczych wykonujących
                    na przykład zakończenia śrub przesuwających suwnice, wałków łożysk itd.
                `,
                opis5:`
                    Przygotowanie programów tnących dla wycięcia wszystkich elementów suwnic, które zostały wycięte wodą z płyty aluminiowej.
                `,
                opis6:`
                    Zmontowanie całości elementów mechanicznych w jedną działającą całość (włącznie z obróbką mechaniczną wyciętych elementów
                    aluminiowych i stalowych)
                `,
                opis7:`
                    Zaprojektowanie, wykonanie i uruchomienie układów elektronicznych potrzebnych do pracy urządzenia.
                `,
                opis8:`
                    Napisania oprogramowania dotyczącego odpowiedniego sterowania silnikami krokowymi (chodzi o odpowiednie
                    przyspieszanie i wyhamowywanie ze względu na wagę suwnic)
                `,
                opis9:`
                    Napisania dwóch rodzajów oprogramowania (dwuwymiarowego i trójwymiarowego) do współpracy z oprogramowaniem CAD
                `,
                opis10:`
                    Urządzenie to umożliwia praktycznie nieograniczone możliwości jeśli chodzi o wykonywanie takich prac jak wycinanie
                    dowolnych kształtów, grawerowanie, wykonywanie płaskorzeźb itd. Oprogramowanie, które stworzyłem współpracuje z popularnymi
                    programami typu CAD takimi jak 3DMax Studio, AutoCad, CorelDraw, Cenon, QCad, Blender.
                `,
                zdjecia1:[
                    {"obraz":"frezarka1.jpg", "tytul": "widok całości"},
                    {"obraz":"frezarka2.jpg", "tytul": "wygląd osi Y i Z"},
                    {"obraz":"frezarka3.jpg", "tytul": "budowa osi Z"},
                ],
                zdjecia2:[
                    {"obraz":"frezarka4.jpg", "tytul": "program QCad", "opis":"w którym stół został zaprojektowany"},
                    {"obraz":"frezarka5.jpg", "tytul": "program sterujący", "opis":"z trójwymiarowym podglądem pracy stołu"},
                    {"obraz":"frezarka6.jpg", "tytul": "aktualnie frezowany projekt"},
                ],
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia2"></MyCarousel>
        <MyText v-bind:mytext="opis3"></MyText>
        <MyText v-bind:mytext="opis4"></MyText>
        <MyText v-bind:mytext="opis5"></MyText>
        <MyText v-bind:mytext="opis6"></MyText>
        <MyText v-bind:mytext="opis7"></MyText>
        <MyText v-bind:mytext="opis8"></MyText>
        <MyText v-bind:mytext="opis9"></MyText>
        <MyText v-bind:mytext="opis10"></MyText>
    </div>
</template>