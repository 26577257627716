<script>
import io from "socket.io-client"
import TopStrony from './components/TopStrony.vue'
// import StronaPolozenie from './components/StronaPolozenie.vue'
import SrodekStrony from './components/SrodekStrony.vue'
import DolStrony from './components/DolStrony.vue'


export default {
  name: 'App',
  data(){
    return{
      socket: {},
      VModel: {
        // 'router': ['O mnie'],
        'router': ['jeden', 'Dwa', 'Trzy'],
        'test': 'test'
      }
    }
  },
  components: {
    // HelloWorld,
    TopStrony,
    // StronaPolozenie,
    SrodekStrony,
    DolStrony
  },
  computed: {
    PathName(){
      return window.location.pathname
    }
  },
  methods:{
    TestKlik: function(){
      console.warn(window.location.pathname)
    }
  },
  created(){
    // this.socket = io("http://192.168.43.180:3000")
    this.socket = io("http://hicad.pl:8000")
    
  },
  mounted(){
    console.log(window.location.hostname)
    let parametr = window.location.href.split('?')[1]
    if (parametr != undefined){
      console.warn("zdefiniowany " + parametr)
      let klucz = parametr.split('=')[0]
      let identyfikator=parametr.split('=')[1]
      if (klucz == "site"){
        // console.warn("identyfikator " + identyfikator)
        document.cookie="klucz="+identyfikator+"; expires=Fri, 31 Dec 9999 00:00:01 GMT"
      }

    }
    if (document.cookie != ""){
      let informacja ={}
      informacja.lokacja = window.location.pathname
      informacja.ciastko=document.cookie
      informacja.domena=window.location.hostname
      this.socket.emit("info", informacja )
    }
  }
}

</script>

<template>
  <div id="app">
    <b-container id="app_container">
      <!-- {{ PathName }} -->
      <TopStrony v-model="VModel"></TopStrony>
      <!-- <StronaPolozenie v-model="VModel"></StronaPolozenie> -->
      <br>
      <SrodekStrony v-model="VModel"></SrodekStrony>
      <DolStrony></DolStrony>
      <br>
      <!-- {{ VModel }} -->
      
    </b-container>
  </div>
</template>

