<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default{
        name: "PrjDomotyka",
        data() {
            return { 
                naglowek: {
                    "title":"komputer sterujący inteligentnym budynkiem",
                    "text": "oprogramowanie oparte o HMI firmy Weintek",
                    "rok": "projekt z roku 2017",
                    // "dodatek": "urządzenie to zostało opatentowane"
                },
                opis1:` 
                    Rozwiązanie komputera sterującego inteligentnym budynkiem oparte zostało o Serwer firmy Weintek typu cMT-SVR-100. Jest to
                    innowacyjne i tanie rozwiązanie HMI, w którym rozdzielone zostały funkcje samego sterownika i ekranu. cMT-SVR można elastycznie
                    łączyć z ekranem dotykowym cMT-iV6, komputerem PC, tabletem, telefonem z systemem Android lub iOS a także telewizorem. 
                    Takie rozwiązanie pozwala na zastosowanie monitorów o dużej przekątnej ekranu, a także na monitorowanie i sterowanie za pomocą
                    urządzeń mobilnych. Do urządzenia można łączyć się również poprzez Chmurę. Pozwoliło to uzyskać wszystkie niezbędne kanały dostępu
                    do sterowania inteligentnym budynkiem, ale również uzyskać ciągłą i niezachwianą pracę budynku w przypadku braku internetu.
                `,
                zdjecia1:[
                    {"obraz":"domotykakomputer1a.jpg"},
                    {"obraz":"domotykakomputer1b.jpg"},
                    
                ],
                opis2:`
                    Oprogramowanie sterujące opracowane zostało w EasyBuilder Pro. Funkcjonalność, którą należało uzyskać, oparta została między innymi o 
                    współpracujące ze sobą makra. Obsługują one zarówno zewnętrzny sprzęt sterujący budynkiem, jak i wewnętrzne mechanizmy udostępniające
                    użytkownikom funkcje i możliwości konfiguracyjne.
                `,
                opis2a:`
                    Funkcjonalność systemu rozdzielona została na dwie główne gałęzie. Pierwszą z nich jest sekcja przeznaczona dla instalatora budynku,
                    drugą zaś dział programu przeznaczony dla użytkowników.
                `,
                naglowek1: {
                    // "title":"moduł hC-PK 01",
                    "text": "instalator budynku",
                },

                zdjecia2:[
                    {"obraz":"domotykakomputer2a.jpg"},
                    {"obraz":"domotykakomputer2b.jpg"},
                    {"obraz":"domotykakomputer2c.jpg"},
                    {"obraz":"domotykakomputer2d.jpg"},
                ],

                opis3:`
                    Oprogramowanie zostało przemyślane w taki sposób, aby instalator mógł zdefiniować odpowiedniego rodzaju obiekty występujące na 
                    budynku w formie programowej, a następnie podłączyć do tych obiektów fizyczne urządzenia. Przykładem obiektów prostych są przekaźniki,
                    włączniki, krańcówki, czujniki temperatury itp. Pozwala to uzyskać wirtualne odpowiedniki fizycznych urządzeń wewnątrz oprogramowania.
                    Możliwe jest także zdefiniowanie obiektów złożonych z obiektów prostych. Jednym z przykładów takich obiektów może być Furtka wejściowa 
                    na posesję składająca się (tutaj w uproszczeniu) z rygla i przycisku. Drugim większym i bardziej skomplikowanym przykładem obiektu
                    złożonego jest Ogród (Podlewanie). Takie założenie pozwala oddać końcowemu użytkownikowi budynku szereg prostych, gotowych różnorodnych
                    obiektów, które następnie można ze sobą łączyć w pożądaną, inteligentną funkcjonalność.
                `,
                naglowek2: {
                    // "title":"moduł hC-PK 01",
                    "text": "użytkownicy budynku",
                },

                
                
                opis4:`
                    Oczywiście ostatecznymi użytkownikami oprogramowania będą lokatorzy. W tej sekcji programu, oprócz codziennego jego 
                    użytkowania, możliwe jest stworzenie przez użytkowników powiązań pomiędzy obiektami wysokiego poziomu. 
                `,
                zdjecia3:[
                    {"obraz":"domotykakomputer3a.jpg"},
                    {"obraz":"domotykakomputer3b.jpg"},
                    {"obraz":"domotykakomputer3c.jpg"},
                ],
                opis4a:`
                    Dzięki odpowiedniemu, autorskiemu podejściu do zagadnienia, lokator mieszkania nie pogubi się w powiązaniach. Podkreślam to,
                    ponieważ podczas pisania programu ważnym dla mnie było, aby to sami użytkownicy mogli wprowadzać inteligencję w budynek, 
                    bez udziału i pomocy specjalistów. W przypadku, kiedy stwierdzone zostanie niewłaściwe zachowanie jakiegoś elementu, bardzo 
                    łatwo znaleźć przyczynę takiego powiązania, ponieważ można je przeanalizować zarówno ze strony przyczyny zjawiska jak również 
                    ze strony celu sterowanego. 
                `,
                opis4b:`
                    Jako przykładem posłużę się tutaj najprostszą żarówką, jedną z dwustu obsługiwanych przez system. Każda z nich ma do dyspozycji
                    możliwość automatycznego wyłączenia po pewnym czasie, z tym że możliwe jest wywołanie na żądanie tej funkcji. Przydatne jest to 
                    na przykład na klatkach schodowych, kiedy zwykłe naciśnięcie przycisku spowoduje ciągłe świecenie, a przytrzymanie przez chwilę 
                    przycisku naściennego spowoduje wywołanie funkcji timera. Dodatkowo Każdą z żarówek możemy uzależnić od jednej z dziesięciu 
                    różnie skonfigurowanych zmierzchówek, Jednego z pięćdziesięciu dostępnych kalendarzy, ośmiu dowolnych przycisków chwilowych 
                    umieszczonych w instalacji, Drzwi obsługiwanych przez czujniki, Czujek ruchu i Stref alarmowych. 
                `,
                zdjecia4:[
                    {"obraz":"domotykakomputer4a.jpg"},
                    {"obraz":"domotykakomputer4b.jpg"},
                    {"obraz":"domotykakomputer4c.jpg"},
                ],
                opis5:`
                    Oprogramowanie to obsługuje bardzo wiele rodzajów obiektów, stąd tutaj ograniczę się jeszcze tylko do jednego ciekawego przykładu, 
                    o którym już wspomniałem, a jest nim włącznik naścienny chwilowy. Przy zastosowaniu włączników dwu sekcyjnych, góra i dół, uzyskujemy
                    cztery sygnały. Oprogramowanie pozwala zbadać czas naciśnięcia każdego z dwustu dostępnych takich włączników z dokładnością do 0,1 
                    sekundy. Pozwala to uzyskać obiekt takiego przycisku który wie, że został naciśnięty krótko, dłużej i długo. Daje to 12 możliwych 
                    stanów z pojedynczego włącznika naściennego, które możemy skojarzyć z dowolnym innym obiektem na budynku, którym będziemy sterować. 
                    Dodatkowo przyciski potrafią również zachować się odpowiednio w przypadku, w którym zechcemy ich użyć do zwiększenia lub zmniejszenia
                    jakiejś wartości lub zjawiska.
                `,


                naglowek3: {
                    // "title":"moduł hC-PK 01",
                    "text": "możliwości oprogramowania",
                },
                
                
                // zdjecia2:[
                //     {"obraz":"domotyka2.jpg", "tytul": "widok od strony Modbus"},
                //     {"obraz":"domotyka3.jpg", "tytul": "widok od strony zasilania"},
                //     {"obraz":"domotyka5.jpg", "tytul": "sterownik przekaźników", "opis":"wraz z połową maksymalnych możliwości sterujących"},
                //     {"obraz":"domotyka6.jpg"},
                // ],
                
                
                
                opis6:`
                    Tak jak wspominałem oprogramowanie to obsługuje szeroki zakres możliwości, stąd ograniczę się do wymienienia całych dziedzin 
                    obsługujących inteligentny dom. Pomiędzy tymi dziedzinami można stwarzać wygodne i inteligentne powiązania. System obsługuje 
                    między innymi:
                `,
                opis6a:`
                    Oświetlenie 
                `,
                opis6b:`
                    Inteligentne włączniki
                `,
                opis6c:`
                    Kalendarze
                `,
                opis6d:`
                    Zmierzchówki
                `,
                opis6e:`
                    Dowolna konfiguracja pomieszczeń i tworzenie pomieszczeń wirtualnych
                `,
                opis6f:`
                    Powiadomienia na obiekcie, dzwonki
                `,
                opis6g:`
                    Obsługa ogrzewania Kaloryferowego i podłogowego
                `,
                opis6h:`
                    Oddzielne Ogrody z zarządzaniem poborem wody i obsługą pogody
                `,
                opis6i:`
                    Czujki ruchu i strefy alarmowe, współpracę z systemem alarmowym
                `,
                opis6j:`
                    Obsługa okien zewnętrznych, zarówno ich otwarcie, jak również sterowanie żaluzjami
                `,
                opis6k:`
                    Obsługa Drzwi wewnętrznych budynku
                `,
                opis6l:`
                    Obsługa pieca, klimatyzacji i rekuperacji
                `,
                opis6m:`
                    Obsługa furtek i bram wjazdowych
                `,
                opis6n:`
                    Analizy statystyczne zjawisk i zużycia energii.
                `,                
                opis7:`
                    Ze względu na mnogość opcji nie będę opisywał możliwości konfiguracji wszystkich powiązań i zależności pomiędzy dziedzinami lub 
                    w ich obrębie. Nie chcę, aby artykuł ten zmienił się w instrukcję obsługi programu :-) Również umieszczone screeny z aplikacji 
                    sterującej, zostały ograniczone do minimum, stanowiąc formę ciekawostki i ogólnego wyglądu programu.
                `,   
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
        <MyText v-bind:mytext="opis2a"></MyText>
        <MyJumbotron v-bind:myjumbotron="naglowek1"></MyJumbotron>
        <MyCarousel v-bind:mycarousel="zdjecia2"></MyCarousel>
        <MyText v-bind:mytext="opis3"></MyText>

        <MyJumbotron v-bind:myjumbotron="naglowek2"></MyJumbotron>
        <MyText v-bind:mytext="opis4"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia3"></MyCarousel>
        <MyText v-bind:mytext="opis4a"></MyText>
        <MyText v-bind:mytext="opis4b"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia4"></MyCarousel>
        <MyText v-bind:mytext="opis5"></MyText>

        <MyJumbotron v-bind:myjumbotron="naglowek3"></MyJumbotron>
        <MyText v-bind:mytext="opis6"></MyText>
        <MyText v-bind:mytext="opis6a"></MyText>
        <MyText v-bind:mytext="opis6b"></MyText>
        <MyText v-bind:mytext="opis6c"></MyText>
        <MyText v-bind:mytext="opis6d"></MyText>
        <MyText v-bind:mytext="opis6e"></MyText>
        <MyText v-bind:mytext="opis6f"></MyText>
        <MyText v-bind:mytext="opis6g"></MyText>
        <MyText v-bind:mytext="opis6h"></MyText>
        <MyText v-bind:mytext="opis6i"></MyText>
        <MyText v-bind:mytext="opis6j"></MyText>
        <MyText v-bind:mytext="opis6k"></MyText>
        <MyText v-bind:mytext="opis6l"></MyText>
        <MyText v-bind:mytext="opis6m"></MyText>
        <MyText v-bind:mytext="opis6n"></MyText>
        <MyText v-bind:mytext="opis7"></MyText>
    </div>
</template>