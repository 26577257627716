<script>
    import MyJumbotron from './komp/MyJumbotron.vue';
    import MyText from './komp/MyText.vue';
    import MyCarousel from './komp/MyCarousel.vue';

    export default{
        name: "PrjWiertarki",
        data() {
            return { 
                naglowek: {
                    "title":"wiertarko-frezarki firmy WESSEL",
                    "text": "oprogramowanie do konwersji programu wiertniczo frezującego",
                    "rok": "projekt z roku 2002",
                },
                opis1:` 
                    Oprogramowanie to jest dedykowane specjalnie dla wiertarko-frezarek
                    firmy WESSEL. Program, przekształca zbiory z formatu typu GERBER na gotowy
                    program wiertniczo-frezujący dla urządzenia.
                `,
                opis2:`
                    Program ten zawiera kompletne informacje potrzebne dla wiercenia otworów
                    wstępnych pod frez. Zapobiega to łamaniu się frezu w czasie rozpoczęcia
                    frezowania laminatu.
                `,
                opis3:`
                    Dodatkowa opcja programu umożliwia wstawienie otworów wstępnych w przypadku,
                    kiedy wiercony otwór posiada średnicę większą niż 3mm. Zapobiega to niszczeniu
                    się wrzecion wiertarki.
                `,
                zdjecia1:[
                    {"obraz":"wiertarkofrezarki.jpg"},
                ],
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
        <MyText v-bind:mytext="opis3"></MyText>
    </div>
</template>