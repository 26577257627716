<script>
    export default{
        name:"MyJumbotron",
        data() {
            return { 

            }
        },
        /** 
         * PROPS MYJUMBOTRON
         * {
         * "title":"Kabina 3D",
         * "text": "Kabina multimedialna 3D do symulacji i prezentacji pomieszczeń",
         * "rok": "projekt z roku 2012",
         * "dodatek": "urządzenie to zostało opatentowane" 
         * }
        */
        props: ["myjumbotron"]
    }
</script>

<template>
    <!-- <div class="jumbotron" align="center">
        <h1 v-if="myjumbotron.title">{{ myjumbotron.title }}</h1>
        <h4 v-if="myjumbotron.text">{{ myjumbotron.text }}</h4>
        <br>
        <h5 v-if="myjumbotron.rok" align="left">{{ myjumbotron.rok }}</h5>
        <h6 v-if="myjumbotron.dodatek" align="right">{{ myjumbotron.dodatek }}</h6>
    </div> -->
    <div  class="myjumb px-3 py-1 mb-3" align="center">
        <h2 v-if="myjumbotron.title"><b>{{ myjumbotron.title }}</b></h2>
        <h4 v-if="myjumbotron.text">{{ myjumbotron.text }}</h4>
        <!-- <br> -->
        <h5 v-if="myjumbotron.rok" align="left">{{ myjumbotron.rok }}</h5>
        <h6 v-if="myjumbotron.dodatek" align="right">{{ myjumbotron.dodatek }}</h6>
    </div>
</template>

<style>
.myjumb{
    background-color: rgb(231, 231, 255);
}
</style>
