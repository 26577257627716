<script>
    export default {
        name: "MyText",
        data() {
            return { 
                // mytext: "testowy"
            }
        },
        /** 
         * PROPS MYCARD
         * string
        */
        props: ["mytext"]
    }
</script>

<template>
    <div>
        <p class="first" align="center">{{ mytext }}</p>
    </div>
</template>

<style>
.first:first-letter {
	font-weight: bold;
	font-size: 1.1em;
    margin-left: 20px;
}
</style>