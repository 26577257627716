<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCard from './komp/MyCard.vue'
    import MyYoutube from './komp/MyYoutube.vue'

    export default{
        name: "NapisaliOMnie",
        data() {
            return { 
                klasa: "col-sm-6 col-md-4 col-lg-3 mb-3",
                naglowek: {
                    "title":"napisali o mnie",
                },
                opis1:` 
                    Projekt Kabina Multimedialna jest unikatową, innowacyjną konstrukcją. Z tego względu stał się on tematem zainteresowania
                    portali internetowych zajmujących się opisywaniem nowinek technologicznych, technicznych i multimedialnych. Na tej stronie
                    umieściłem kopie i linki do artykułów dotyczących opisu mojego wynalazku jak również mojej osoby.
                `,
                yuotube1:{
                    "zrodlo": "https://www.youtube.com/embed/Ht_8o3qcc7Y",
                    "opis": "fragment audycji w polskim radio czwórka"
                },
                czworka: {
                    "image":"LogoCzworka.jpg",
                    "title":"polskie radio czwórka",
                    "mylinkext": { 
                        "text":"oryginał artykułu", 
                        "sciezka": "https://www.polskieradio.pl/10/481/Artykul/796967,kabina-3d-zmienia-rzeczywistosc"
                    },
                    "mylinkdownload": { 
                        "text":"pobierz kopię", 
                        "sciezka": "./images/artykul_polskieradio.jpg"
                    }
                },
                chip: {
                    "image":"LogoChip.png",
                    "title":"czasopismo komputerowe chip.pl",
                    "mylinkext": { 
                        "text":"oryginał artykułu", 
                        "sciezka": "https://www.chip.pl/2012/02/kabina-multimedialna-do-prezentacji-i-symulacji-pomieszczen"
                    },
                    "mylinkdownload": { 
                        "text":"pobierz kopię", 
                        "sciezka": "./images/artykul_chip_pl.jpg"
                    }
                },
                wp: {
                    "image":"LogoWp.png", 
                    "title":"portal informacyjny wp.pl",
                    "mylinkext": { 
                        "text":"oryginał artykułu", 
                        "sciezka": "https://tech.wp.pl/kabina-3d-rewolucyjny-polski-wynalazek,6034806509253761a"
                    },
                    "mylinkdownload": { 
                        "text":"pobierz kopię", 
                        "sciezka": "./images/artykul_wp_pl.jpg"
                    }
                },
                nowtech: {
                    "image":"LogoNowinkiTechniczne.jpg",
                    "title":"Portal z nowinkami technologicznymi",
                    "mylinkdownload": { 
                        "text":"pobierz kopię", 
                        "sciezka": "./images/artykul_nowinki_techniczne.jpg"
                    }
                },
                webblog: {
                    "image":"LogoWebBlog.jpg",
                    "title":"Portal z nowinkami technologicznymi",
                    "mylinkdownload": { 
                        "text":"pobierz kopię", 
                        "sciezka": "./images/artykul_weblog_info_pl.jpg"
                    }
                },
                technow: {
                    "image":"LogoTechnow.gif", 
                    "title":"Portal z nowinkami technologicznymi",
                    "mylinkdownload": { 
                        "text":"pobierz kopię", 
                        "sciezka": "./images/artykul_technow_pl.jpg"
                    }
                },
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCard,
            MyYoutube
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyYoutube v-bind:myyoutube="yuotube1"></MyYoutube>
        <div class="row">
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="czworka"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="wp"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="chip"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="nowtech"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="webblog"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="technow"></MyCard>
            </div>
        </div>
    </div>
</template>