<script>
    import MyJumbotron from "./komp/MyJumbotron.vue"
    import MyText from "./komp/MyText.vue"
    import MyCard from "./komp/MyCard.vue"

    export default {
        name: "OMnie",
        data() {
            return { 
                klasa: "col-sm-6 col-md-4 col-lg-3 mb-3",
                naglowek: {
                    "title":"o mnie"
                },
                opis:` 
                    Jestem osobą zajmującą się projektowaniem urządzeń elektronicznych, mechanicznych i tworzeniem oprogramowania.
                    Wprowadzam również udoskonalenia działających urządzeń w zakładach produkcyjnych (automatyka przemysłowa). 
                    Jestem w stanie zrealizować najnowocześniejsze rozwiązania technologiczne. Dokładam wszelkich starań, 
                    aby urządzenia, które opracowuje, były nowoczesne, intuicyjne w obsłudze i niezawodne.`,
                mikro: {"image":"ProgMikroko.jpg", "title":"Mikrokontrolery", "text": "Programowanie mikrokontrolerów jednoukładowych"},
                projekt: {"image":"ProjElektr.jpg", "title":"Elektronika", "text": "Projektowanie urządzeń elektronicznych"},
                neuron: {"image":"SieciNeuron.jpg", "title":"Sieci neuronowe", "text": "Projekty wykorzystujące sieci neuronowe"},
                programpc: {"image":"ProgramowaniePC.jpg", "title":"Programistyka", "text": "Programowanie aplikacji na Windows i Linux"},
                programplc: {"image":"SterPLC.jpg", "title":"PLC", "text": "Programowanie sterowników przemysłowych PLC"},
                programhmi: {"image":"SterHMI.jpg", "title":"HMI", "text": "Programowanie ekranów przemysłowych HMI"},
                montaz: {"image":"MontazPCB.jpg", "title":"Montaż", "text": "Montaż elementów elektronicznych"},
                mechanika: {"image":"Mechanika.jpg", "title":"Mechanika", "text": "Projektowanie, wykonanie rozwiązań mechanicznych"},
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCard
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis"></MyText>
        <div class="row">
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="mikro"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="projekt"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="neuron"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="programpc"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="programplc"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="programhmi"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="montaz"></MyCard>
            </div>
            <div v-bind:class="klasa">
                <MyCard v-bind:mycard="mechanika"></MyCard>
            </div>
        </div>
      

    </div>
</template>