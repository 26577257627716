<script>
     export default {
        name: "DolStrony",
        data() {
            return { 

            }
        }
    }
</script>

<template>
    <div>
        <!--  type="dark" variant="dark" -->
        <b-navbar toggleable="lg">
            <b-navbar-nav>
                <b-nav-text>start &copy; 2010</b-nav-text>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-text>modernizacja &copy; 2022</b-nav-text>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>