<script>
/** 
 * Do wyświetlania po kliknięciu większego zdjęcia można zastosować komponent MODAL
 * https://bootstrap-vue.org/docs/components/embed
*/
    export default{
        name: "MyCarousel",
        data() {
            return { 

            }
        },
        /** 
         * PROPS MYCARD
         * [
         *     {
         *        "obraz":"kabina2.jpg",
         *        "tytul": "proporcje wielkości",
         *        "opis":"wielkość urządzenia w porównaniu z człowiekiem"
         *     },
         *     {
         *        "obraz":"kabina3.jpg",
         *        "tytul": "ściany dotykowe",
         *        "opis":"urządzenie posiada dwie takie ściany dotykowe"
         *     }
         * ]
        */
        props: ["mycarousel"],
        methods:{
            Sciezka(Obraz){
                return "./images/"+Obraz
            }
        }

    }
</script>

<template>
    <div class="row justify-content-md-center">
        <div class="col col-sm-12 col-md-10 col-lg-8 pb-4">
            <b-carousel
                id="karuzela"
                :interval="4000"
                fade
                controls
                indicators
                style="text-shadow: 1px 1px 2px #333;"
            >
                <b-carousel-slide v-for="item in mycarousel"
                    v-bind:key="item.id"
                    v-bind:caption="item.tytul"
                    v-bind:text="item.opis"
                    v-bind:img-src="Sciezka(item.obraz)"
                    content-visible-up="md"
                ></b-carousel-slide>
            </b-carousel>
        </div>
    </div>
</template>

<style>
.carousel-control-next,
.carousel-control-prev,
.carousel-label,
.carousel-indicators  {
    filter: invert(1);
}
</style>