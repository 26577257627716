<script>
    import MyJumbotron from './komp/MyJumbotron.vue'
    import MyText from './komp/MyText.vue'
    import MyCarousel from './komp/MyCarousel.vue'

    export default{
        name: "PrjOdczucie",
        data() {
            return { 
                naglowek: {
                    "title":"system prowądzacy analizę reklam multimedialnych",
                    // "text": "Kabina multimedialna 3D do symulacji i prezentacji pomieszczeń",
                    "rok": "projekt z roku 2005",
                },
                opis1:` 
                    Urządzenie przeznaczone jest do prowadzenia statystycznego odczucia odbioru reklamy telewizyjnej przez odbiorcę.
                    Badanie polega na tym, iż pewna ilość ludzi ogląda na dużym ekranie materiał filmowy i za pomocą urządzenia trzymanego
                    w ręku, wskazuje czy dany fragment materiału wideo wyzwala u nich negatywne, obojętne czy pozytywne emocje, przy czym
                    każdy z zakresów negatywnych i pozytywnych został podzielony na dziesięć części. Informacje z każdego z urządzeń spływają
                    do komputera, który dokonuje analizy bieżącej i w postaci wykresu wyświetla na materiale filmowym.
                `,
                opis2:`
                    Urządzenie to oczywiście jest zasilane bateryjnie aby uniknąć dużej ilości przewodów podczas takiego badania, a informacja
                    o stanie poszczególnych urządzeń wędruje drogą radiową do komputera. Jednym z największych wyzwań była ilość informacji,
                    jaka musi zostać przekazana do komputera z urządzeń, ponieważ analiza prowadzona jest w czasie rzeczywistym. Duża ilość
                    informacji wymagana jest dla zachowania płynności wykresów i możliwości szybkiej zmiany decyzji przez użytkowników.
                    Do tego urządzenie musiało być możliwie tanie w produkcji więc tory radiowe zastosowane w rozwiązaniu były wykorzystane
                    do granic możliwości z uwzględnieniem specjalnie do tego celu zaprojektowanego przeze mnie protokołu transmisji.
                `,
                zdjecia1:[
                    {"obraz":"odczucie.jpg"},
                ],
            }
        },
        components:{
            MyJumbotron,
            MyText,
            MyCarousel
        },
        methods:{
       
        }
    }
</script>

<template>
    <div>
        <MyJumbotron v-bind:myjumbotron="naglowek"></MyJumbotron>
        <MyText v-bind:mytext="opis1"></MyText>
        <MyCarousel v-bind:mycarousel="zdjecia1"></MyCarousel>
        <MyText v-bind:mytext="opis2"></MyText>
    </div>
</template>